import React from "react";
import { Link } from "gatsby";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

function CampaignRedirectPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="my-8 p-3 text-center">
        <h2 className="bg-orange text-2xl font-bold inline-block p-2">
          404: Page not found
        </h2>
        <h3 className="c-h5 text-orange py-8 underline text-center">
          <Link to="/">Go back to our homepage →</Link>
        </h3>
      </div>
    </Layout>
  );
}

export default CampaignRedirectPage;
